import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-coming-soon",
      "style": {
        "position": "relative"
      }
    }}>{`⌛ Coming Soon!`}<a parentName="h1" {...{
        "href": "#-coming-soon",
        "aria-label": " coming soon permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      